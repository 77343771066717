<template>
  <PageContainer
    :title="pageContainerConfig.title"
    :back="pageContainerConfig.back"
  >
    <template v-slot:content>
      <div class="bussiness page-segment" v-if="company">
        <div class="page-segment-content">
          <PersonCard :image="company.logo" :title="company.name" />
          <Chip
            :text="$translations.bussiness['benefit-active']"
            color="green"
            size="small"
          />
          <div class="bussiness-body">
            <div class="bussiness-body_title">
              {{ $translations.form.description }}
            </div>
            <div class="bussiness-body_description">
              {{ company.welcome_message }}
            </div>
            <ul class="bussiness-body-benefit">
              <li
                class="bussiness-body-benefit_item"
                v-for="(item, index) of benefits"
                :key="index"
              >
                {{ item.name }} - {{ item.percentage_value }}%
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import { Promos, Chip, PageContainer, PersonCard } from '@seliaco/red-panda'

export default {
  components: {
    PersonCard,
    Chip,
    PageContainer
  },
  data () {
    return {
      company: null,
      benefits: null,
      pageContainerConfig: {
        title: this.$translations.bussiness.title,
        back: {
          callback: () => this.closeAndSafe()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  mounted () {
    this.getBussinessInfo()
  },
  methods: {
    async getBussinessInfo () {
      const promo = await Promos.Benefits.getBenefits()

      this.company = promo.company
      this.benefits = promo.benefits
    },
    closeAndSafe () {
      Promos.Benefits.updateAcknowledge()
      localStorage.setItem('BussinessBenefit', JSON.stringify(true))
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="sass" scoped>
.page-segment-content
  display: flex
  flex-direction: column
  gap: 16px

.bussiness
  &-body
    &_title
      font-weight: 600
      font-size: var(--tiny)
      line-height: 20px
      color: var(--gray-60)
    &_description,
    &-benefit
      font-size: var(--tiny)
      line-height: 150%
      color: var(--gray-60)

    &-benefit
      padding-left: 16px
      margin-top: 16px
      &_item
        list-style: disc
</style>
